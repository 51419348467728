import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationRu from "./locales/ru/translation.json";
import translationBy from "./locales/by/translation.json";

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: false,
    lng: "ru",
    fallbackLng: "by",

    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },

    resources: {
      ru: {
        translations: translationRu,
      },
      by: {
        translations: translationBy,
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n;