import { useState } from 'react';
import { Row, Col, Drawer } from 'antd';
import { withTranslation } from 'react-i18next';
import Container from '../../common/Container';
import { SvgIcon } from '../../common/SvgIcon';
import {
	HeaderSection,
	LogoContainer,
	Burger,
	NotHidden,
	CustomNavLinkSmall,
	Label,
	Outline,
	Span,
	Menu,
} from './styles';

const Header = ({ t }: any) => {
	const [open, setOpen] = useState(false);

	const showDrawer = () => {
		setOpen(true);
	  };
	
	  const onClose = () => {
		setOpen(false);
	  };

	const MenuItem = () => {
		const scrollTo = (id: string) => {
			const element = document.getElementById(id) as HTMLDivElement;
			element.scrollIntoView({
				behavior: 'smooth',
			});
			setOpen(false);
		};
		return (
			<>
				<CustomNavLinkSmall onClick={() => scrollTo('about')}>
					<Span>{t('о нас')}</Span>
				</CustomNavLinkSmall>
				<CustomNavLinkSmall onClick={() => scrollTo('service')}>
					<Span>{t('услуги')}</Span>
				</CustomNavLinkSmall>
				<CustomNavLinkSmall onClick={() => scrollTo('price')}>
					<Span>{t('цены')}</Span>
				</CustomNavLinkSmall>
				<CustomNavLinkSmall	onClick={() => scrollTo('contact')}>
					<Span>{t('контакты')}</Span>
				</CustomNavLinkSmall>
				<a className='header-contact' href='tel:+375291774658'>+375291774658</a>
			</>
		);
	};

	return (
		<HeaderSection>
			<Container>
				<Row justify="space-between">
					<LogoContainer to="/" aria-label="homepage">
						<SvgIcon src="logo.svg" width="60px" height="60px" />
					</LogoContainer>
					<NotHidden>
						<MenuItem />
					</NotHidden>
					<Burger onClick={showDrawer}>
						<Outline />
					</Burger>
				</Row>
				<Drawer closable={false} open={open} onClose={onClose}>
					<Col style={{ marginBottom: '2rem' }}>
						<Label onClick={onClose}>
							<Col span={12}>
								<LogoContainer to="/" aria-label="homepage">
									<SvgIcon src="logo.svg" width="60px" height="60px" />
								</LogoContainer>
							</Col>
							<Col span={12}>
								<Outline />
							</Col>
						</Label>
					</Col>
					<Menu>Меню</Menu>
					<MenuItem />
				</Drawer>
			</Container>
		</HeaderSection>
	);
};

export default withTranslation()(Header);
