import { createGlobalStyle } from 'styled-components';

export const Styles = createGlobalStyle`

    @font-face {
        font-family: "Motiva Sans Light";
        src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Motiva Sans Bold";
        src: url("/fonts/Leto-Text-Sans-Defect.ttf") format("truetype");
        font-style: normal;
    }


    body,
    html,
    a {
        font-family: 'Leto-Text-Sans-Defect.ttf', sans-serif;
    }


    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #1c1c1c;
        overflow-x: hidden;
        width: 100%;
    }

    a:hover {
        color: #cdcdcd;
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: #9c9c9c;
        transition: all 0.3s ease-in-out;  
        outline: none;

        :focus-within {
            background: #cdcdcd;
            box-shadow: 2px 2px 15px 0px rgba(205, 205, 205, 0.43);
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Motiva Sans Bold', serif;
        margin: 1rem 0;
        color: #9c9c9c;
        font-size: 2.5rem;
        line-height: 1.1;

        @media only screen and (max-width: 1220px) {
            font-size: 44px;
          }

        @media only screen and (max-width: 890px) {
          font-size: 38px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 30px;
        }
    }

    p {
        color: #cdcdcd;
        font-size: 1.2rem;        
        line-height: 1.4;

        @media only screen and (max-width: 1220px) {
            font-size: 20px;
          }

        @media only screen and (max-width: 890px) {
          font-size: 18px;
        }

        @media only screen and (max-width: 576px) {
            font-size: 16px;
        }
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #9c9c9c;

        :hover {
            color: #cdcdcd;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
        max-width: 50%;
    }

    #intro .ant-row .img-min, #about .ant-row .img-min, #service-3 .ant-row .img-min {
        max-width: 100%;

        @media only screen and (max-width: 768px) {
            max-width: 70%;
            margin: 0 auto;
        }
    }

    #service-2 .ant-row .img-none, #about .ant-row .img-min {
        max-width: 100%;

        @media only screen and (max-width: 768px) {
            display: none;
        }
    }

    .ant-drawer-body {
        background-color: #1c1c1c;
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 100% !important;
    }

   .ant-table-wrapper .ant-table {
        background: #1c1c1c;
        width: 100%;
        text-align: start;
        border-radius: 0;
        border-collapse: separate;
        border-spacing: 0;
    }

    .ant-table-content {
        margin: 1rem 0;
    }

    .ant-table-wrapper .ant-table-tbody >tr >td {
        border-top: none;
        transition: background 0s;
    }

    .ant-table-wrapper .ant-table-tbody >tr.ant-table-row:hover>td, .ant-table-wrapper .ant-table-tbody >tr >td.ant-table-cell-row-hover {
        background: #cdcdcd;
    }

    .ant-table-wrapper .ant-table-tbody >tr.ant-table-row:hover>td, .ant-table-wrapper .ant-table-tbody >tr >td.ant-table-cell-row-hover > p {
        color: #1c1c1c;
    }

    .ant-table-cell > p {
        margin: 0;
        font-size: 1.1rem;
        font-weight: normal;
        color: #9c9c9c;
    }

    .header-contact {
        
        font-size: 1.25rem;
        color: #9c9c9c;
        transition: color 0.2s ease-in;
        margin: 0.5rem 0 0.5rem 1rem;
        text-align: center;
    
        @media only screen and (max-width: 768px) {
            font-size: 1.5 rem;
            margin: 2rem 2.5rem;
        }
    }

    .footer-contact {
        font-size: 16px;
        color: #1c1c1c;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        display: block;
        margin-bottom: 0.3rem;
        transition: all 0.2s ease-in-out;

	&:hover {
		color: #9c9c9c;
		text-underline-position: under;
		text-decoration: #9c9c9c underline;
	}

	@media screen and (min-width: 768px) {
		.hide-lang {
            display: none;
        }
    }
`;
