import styled from 'styled-components';

export const StyledContainer = styled('div')<any>`
	position: relative;
	max-width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding: 0 70px;
	border-top: ${p => (p.border ? '1px solid #1c1c1c' : '')};

	@media only screen and (max-width: 1024px) {
		max-width: 100%;
		padding: 0 30px;
	}

	@media only screen and (max-width: 768px) {
		max-width: 100%;
		padding: 0 18px;
	}

	@media only screen and (max-width: 414px) {
		max-width: 100%;
		padding: 0 18px;
	}
`;
