import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FooterSection = styled('footer')`
	background: #cdcdcd;
	padding: 2rem 0;
	
	box-shadow: 0 -150px 70px -120px #fff inset,
	0 -220px 70px -120px #cdcdcd inset,
	0 -280px 70px -120px #white inset;
	animation: background 10s infinite alternate;
	
	@keyframes background {
		100% {
			background: #fff;
			box-shadow: 0 -140px 70px -120px white inset,
			0 -210px 70px -120px #cdcdcd inset,
			0 -280px 70px -120px white inset;
		}
	}
`;

export const Title = styled('h4')`
	padding: 2rem 0 0;
	font-size: 20px;
	color: #1c1c1c;
	text-transform: uppercase;
	text-align: center;

	@media screen and (max-width: 576px) {
		text-align: center;
	}
`;

export const NavLink = styled(Link)`
	display: block;
	font-size: 1.4rem;
	margin-bottom: 0.6rem;
	transition: all 0.2s ease-in-out;
	text-align: center;

	&:hover,
	&:active,
	&:focus {
	color: #9c9c9c;
	}
`;

export const Para = styled('div')`
	color: #1c1c1c;
	font-size: 14px;
`;

export const ParaCont = styled('div')`
	color: #1c1c1c;
	font-size: 14px;
	text-align: center;
`;

export const FooterCopy = styled('div')`
	color: #1c1c1c;
	font-size: 13px;
	padding: 3rem 0 0.5rem 0
`;

export const Large = styled(Link)<any>`
	margin: auto;
	font-size: 16px;
	color: #1c1c1c;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	line-height: 24px;
	display: block;
	margin-bottom: 0.6rem;
	transition: all 0.2s ease-in-out;
	max-width: max-content;
	text-align: center;

	&:hover {
		color: #9c9c9c;
		text-underline-position: under;
		text-decoration: #9c9c9c underline;
	}
`;

export const FooterContact = styled('div')`
	font-size: 16px;
	color: #1c1c1c;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	display: block;
	margin-bottom: 0.6rem;
	transition: all 0.2s ease-in-out;
	max-width: max-content;

	&:hover {
		color: #9c9c9c;
		text-underline-position: under;
		text-decoration: #9c9c9c underline;
	}

	@media screen and (max-width: 576px) {
		text-align: center;
	}
`;

export const Label = styled('label')`
	padding: 2rem 0 0;
	font-size: 20px;
	display: block;
    margin: 1rem 0;
	font-weight: bold;
    line-height: 1.1;
	color: #1c1c1c;
	text-transform: uppercase;
	text-align: center;
	font-family: 'Motiva Sans Bold', serif;

	@media screen and (max-width: 414px) {
		padding: 1rem 0;
	}
`;

export const LanguageSwitch = styled('div')<any>`
	cursor: pointer;
	transition: all 0.1s ease-in-out;
	margin: 0 0.5rem;

	&:hover,
	&:active,
	&:focus {
		-webkit-transform: scale(1.1);
		-ms-transform: scale(1.1);
		transform: scale(1.1);
	}
`;

export const LanguageSwitchContainer = styled('div')`
	display: flex;
	justify-content: center;
`;
