/* eslint-disable jsx-a11y/anchor-is-valid */
import { Row, Col } from 'antd';
import { withTranslation } from 'react-i18next';
import { SvgIcon } from '../../common/SvgIcon';
import Container from '../../common/Container';
import { format } from 'date-fns';

import i18n from 'i18next';
import {
	FooterSection,
	Title,
	Para,
	Label,
	LanguageSwitch,
	LanguageSwitchContainer,
	FooterCopy,
	ParaCont
} from './styles';

const Footer = ({ t }: any) => {
	const handleChange = (language: string) => {
		i18n.changeLanguage(language);
	};

	return (
		<>
			<FooterSection>
				<Container>
					<Row justify="space-around">
						<Col lg={8} md={8} sm={12} xs={24}>
							<Title>{t('контакты')}</Title>
							<ParaCont>
								<a className='footer-contact' href='tel:+375291774658'>+375291774658</a>
								<a className='footer-contact' href='mailto:asalvafw@gmail.com'>info@slva.by</a>
							</ParaCont>
							
						</Col>
						<Col lg={8} md={8} sm={12} xs={24}>
							<Title>{t('информация')}</Title>
							<ParaCont>
								<a className='footer-contact' href='#' download={"./politika-konfidencialnosti.pdf"} target="_blank">Политика конфиденциальности</a>
							</ParaCont>
						</Col>
						<Col lg={8} md={8} sm={0} xs={0}>
							<Label htmlFor="select-lang">{t('язык')}</Label>
							<LanguageSwitchContainer>
								<LanguageSwitch
									onClick={() => handleChange('ru')}
								>
									<SvgIcon
										src="russia.png"
										aria-label="homepage"
										width="40px"
										height="40px"
									/>
								</LanguageSwitch>
								<LanguageSwitch
									onClick={() => handleChange('by')}
								>
									<SvgIcon
										src="belarus.png"
										aria-label="homepage"
										width="40px"
										height="40px"
									/>
								</LanguageSwitch>
							</LanguageSwitchContainer>
						</Col>
					</Row>
					<Row justify="space-around">
						<Col lg={24} md={24} sm={24} xs={24}>
							<Title>{t('о нас')}</Title>
							<Row justify="space-between">
								<Col xs={{ span: 23, offset: 1 }} sm={{ span: 20, offset: 2 }} lg={{ span: 11, offset: 1 }}>
									<Para>{t('Частное унитарное предприятие «СЛВА»')}</Para>
									<Para>{t('Свидетельство о государственной регистрации ЧУП «СЛВА» выдано Мингорисполкомом на основании решения от 25.10.2022 № 491. УНП 193653725.')}</Para>
									<Para>{t('220051, г.Минск, ул. Сергея Есенина 73-1Н')}</Para>
								</Col>
								<Col xs={{ span: 23, offset: 1 }} sm={{ span: 20, offset: 2 }} lg={{ span: 11, offset: 1 }}>
									<Para>{t('Банковские реквизиты:')}</Para>
									<Para>{t('р/с BY66PJCB 3012 0771001000000 933 (BYN)')}</Para>
									<Para>{t('ОАО «ПРИОРБАНК»')}</Para>
									<Para>{t('220116, г. Минск, пр-т Дзержинского, 104 (БЦ «Титан»)')}</Para>
									<Para>{t('Код банка: PJCBBY2X')}</Para>
								</Col>
							</Row>
						</Col>
						<Col lg={0} md={0} sm={12} xs={24}>
							<Label htmlFor="select-lang">{t('язык')}</Label>
							<LanguageSwitchContainer>
								<LanguageSwitch
									onClick={() => handleChange('ru')}
								>
									<SvgIcon
										src="russia.png"
										aria-label="homepage"
										width="40px"
										height="40px"
									/>
								</LanguageSwitch>
								<LanguageSwitch
									onClick={() => handleChange('by')}
								>
									<SvgIcon
										src="belarus.png"
										aria-label="homepage"
										width="40px"
										height="40px"
									/>
								</LanguageSwitch>
							</LanguageSwitchContainer>
						</Col>
					</Row>
					<Row justify="space-between">
						<Col lg={24} md={24} sm={24} xs={24}>
							<FooterCopy>Ⓒ 2022-{format(new Date(), 'yyyy')} {t('SLVA Все права защищены')}</FooterCopy>
						</Col>
					</Row>
				</Container>
			</FooterSection>
		</>
	);
};

export default withTranslation()(Footer);